<template>
  <v-container fluid>
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
        background-color="#5E2FBE"
        dark
        disabled
      >
        <v-tab
          :disabled="loading"
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
        <v-spacer></v-spacer>
        <template  v-if="tab == 0 ||  tab == 1">   
            <v-btn
              outlined
              small
              class="my-auto mr-2 rounded-0"
              @click="() => showImportStockAdjustmentModal()" 
              >
              {{
                  this.$t("import_csv")
                }}
            </v-btn>
          <import-stock-adjustment-modal></import-stock-adjustment-modal> 
        </template>
        <template v-if="tab > 0">
          <v-btn
            outlined
            small
            class="ml-auto my-auto mr-2 rounded-0"
            v-if="$admin.can('stockAdjustment-export')"
            @click="getCSVDetails(filters)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
            >
            <v-icon
              color="white"
              class="mr-2"
            >
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_detail_csv") }}
          </v-btn>
               
          <v-btn
            outlined
            small
            class="my-auto mr-2 rounded-0"
            v-if="$admin.can('stockAdjustment-export')"
            @click="getCSVResult(filters)"
            :loading="is_loading_csv_result"
            :disabled="is_loading_csv_result"
          >
            <v-icon
              color="white"
              class="mr-2"
            >
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_result_csv") }}
          </v-btn>
        </template>
        
        <div class="white my-auto mr-2">
          <v-btn
            outlined
            small
            class="primary--text my-auto rounded-0"
            v-if="$admin.can('stockAdjustment-create')"
            @click="addItem()"
          >
            {{ $t("stockAdjustments.add_new_adjustement") }}
          </v-btn>
        </div>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <draft-tab
            :filters="filters"
            v-if="item.content == 'draft'"
            :isActive="items[tab]?.content == 'draft'"
          ></draft-tab>
          <pending-tab
            v-if="item.content == 'pending'"
            :filters="filters"
            :isActive="items[tab]?.content == 'pending'"
          >
          </pending-tab>
          <history-tab
            v-if="item.content == 'history'"
            :filters="filters"
            :isActive="items[tab]?.content == 'history'"
          >
          </history-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
export default {
  components: {
    DraftTab: () => import("./tabs/DraftTab.vue"),
    PendingTab: () => import("./tabs/PendingTab.vue"),
    HistoryTab: () => import("./tabs/HistoryTab.vue"),
    ImportStockAdjustmentModal: () => import("./modals/ImportStockAdjustmentModal.vue"),
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.stockAdjustments.filters;
      },
      set(value) {
        this.$store.commit("stockAdjustments/SET_FILTERS", value);
      },
    },
    selected: {
      get() {
        return this.$store.state.stockAdjustments.selected;
      },
      set(value) {
        this.$store.commit("stockAdjustments/SET_SELECTED", value);
      },
    },
   
  },
  async created() {
    this.tab = this.selected;
    this.filters.status = this?.items[this.tab]?.status ?? "draft";
    this.$store.commit("SET_PAGE_SUBTITLE", "");
    this.$store.commit("SET_SUBTITLE_ID", "");
  },
  data() {
    return {
      tab: 0,
      loading: false,
      is_loading_csv_result: false,
      is_loading_csv: false,
      items: [
        {
          tab: this.$t("draft"),
          content: "draft",
          status: "draft",
          id: 0,
        },
        {
          tab: this.$t("pending"),
          content: "pending",
          status: "pending",
          id: 1,
        },
        {
          tab: this.$t("history"),
          content: "history",
          status: "history",
          id: 2,
        },
      ],
    };
  },
  methods: {
    async tabChanged(index) {
      try {
        this.$store.commit("stockAdjustments/SET_SELECTED", index);
        this.$store.commit("stockAdjustments/CLEAN_LIST");
        this.$store.dispatch("stockAdjustments/setParams", {
          status: this.items[index].status,
        });

        this.filters.status = this?.items[index]?.status;
        await this.$store.dispatch("stockAdjustments/list", this.filters, {
          status: this.items[index].status,
        });
        this.$store.commit("SET_PAGE_SUBTITLE", "");
        this.$store.commit("SET_SUBTITLE_ID", "");
      } catch (error) {
        //this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    addItem() {
      this.$router.push({ name: "stockAdjustments.new" });
    },
    async getCSVDetails(filters) {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("stockAdjustments/downloadCSV", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          let date = new Date();
          let day = String(date.getDate()).padStart(2, "0");
          let month = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
          let year = date.getFullYear();

          let filename = `stock_adjustments_details_${day}_${month}_${year}.xlsx`;

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
    async getCSVResult(filters) {
      this.is_loading_csv_result = true;
      await this.$store
        .dispatch("stockAdjustments/downloadCSVResult", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          let date = new Date();
          let day = String(date.getDate()).padStart(2, "0");
          let month = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
          let year = date.getFullYear();

          let filename = `stock_adjustment_results_${day}_${month}_${year}.xlsx`;

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv_result = false;
        })
        .catch((error) => {
          this.is_loading_csv_result = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
    showImportStockAdjustmentModal() {
       this.$store.commit("stockAdjustments/SET_IMPORT_StockAdjustment_MODAL_VISIBLE", true);
     },
  },
};
</script>